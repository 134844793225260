import axios from 'axios'
import qs from 'qs'
import { message } from 'ant-design-vue'
import router from '../router/index.ts'

/*
 * 根据环境变量区分接口的默认地址
 */
switch (process.env.NODE_ENV) {
  // 生产环境
  case 'production':
    axios.defaults.baseURL = 'https://java.yedeqin.com'
    break
  // 测试环境
  default:
    axios.defaults.baseURL = 'http://127.0.0.1:8081'
}

/*
 * 设置超时时间和跨域是否允许携带凭证
 */
axios.defaults.timeout = 100000
axios.defaults.withCredentials = true

/*
 * 设置请求传递数据的格式
 * x-www-form-urlendoded
 */
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.transformRequest = (data) => {
  if (data instanceof FormData) {
    return data
  } else {
    return qs.stringify(data)
  }
}

/*
 * 设置请求拦截器
 */
axios.interceptors.request.use(
  (config) => {
    // 携带token
    // const token = localStorage.getItem('token')
    // token && (config.headers.Authorization = token)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/*
 * 自定义响应成功的HTTP状态码
 */
axios.defaults.validateStatus = (status) => {
  // 2或3开头的都属于正常状态
  return /^(2|3)\d{2}$/.test(status)
}

/*
 * 设置响应拦截器
 */
axios.interceptors.response.use(
  (response) => {
    if (response.data.code === 0) {
      if (response.data.msg !== 'success') {
        message.success(response.data.msg)
      }
    } else if (response.data.code === -1) {
      message.error(response.data.msg)
    } else if (response.data.code === -2) {
      message.warning(response.data.msg)
      // 未登录，跳转至登录页
      router.push('/login')
    }

    return response.data
  },
  (error) => {
    const { response } = error
    if (response) {
      // 服务器有返回
      switch (response.status) {
        case 401:
          break
        case 404:
          break
        case 500:
          message.error('系统异常:500')
      }
    } else {
      // 服务器没有返回
      if (!window.navigator.onLine) {
        //  断网(可以跳转至断网页面)
        return
      } else {
        message.error('服务器异常！')
      }
      return Promise.reject(error)
    }
  }
)

export default axios

import axios from './http.js'

const LOGIN = (params) => axios.post('user/login', params)
const LOGOUT = () => axios.get('user/logout')
const REGIST = (params) => axios.post('user/insertUser', params)
const TEST = (params) => axios.get('user/testSession', params)

// 新增文章分类
const INSERTCATEGORY = (params) => axios.post('category/insertCategory', params)
// 删除文章分类
const DELETECATEGORY = (params) => axios.post('category/deleteCategory', params)
// 更新文章分类
const UPDATECATEGORY = (params) => axios.post('category/updateCategory', params)
// 查询文章分类
const SELECTCATEGORY = (params) =>
  axios.get('category/selectCategory', { params: params })
// 查询所有文章分类
const SELECTALLCATEGORY = () => axios.get('category/selectAllCategory')

// 新增文章标签
const INSERTTAG = (params) => axios.post('tag/insertTag', params)
// 删除文章标签
const DELETETAG = (params) => axios.post('tag/deleteTag', params)
// 修改文章标签
const UPDATETAG = (params) => axios.post('tag/updateTag', params)
// 查询文章标签
const SELECTTAG = (params) => axios.get('tag/selectTag', { params: params })
// 查询所有文章标签
const SELECTALLTAG = () => axios.get('tag/selectAllTag')

// 新增文章
const INSERTARTICLE = (params) => axios.post('article/insertArticle', params)
// 删除文章
const DELETEARTICLE = (params) => axios.post('article/deleteArticle', params)
// 修改文章
const UPDATEARTICLE = (params) => axios.post('article/updateArticle', params)
// 查询文章
const SELECTARTICLE = (params) =>
  axios.get('article/selectArticle', { params: params })
// 根据id查询一篇文章
const SELECTARTICLEBYID = (params) =>
  axios.get('article/selectArticleById', { params: params })
// 更新文章发布状态
const UPDATEISISSUE = (params) => axios.post('article/updateIsIsissue', params)
// 更新文章置顶状态
const UPDATEISTOP = (params) => axios.post('article/updateIsTop', params)

// 上传图片
const UPLOADPHOTO = (params, config) =>
  axios.post('upload/uploadPhoto', params, config)

// 查询评论
const SELECTARTICLECOMMENT = (params) =>
  axios.get('articleComment/selectArticleComment', { params: params })
// 删除评论
const DELETEARTICLECOMMENT = (params) =>
  axios.post('articleComment/deleteArticleComment', params)

// 查询点赞列表
const SELECTARTICLELIKE = (params) =>
  axios.get('articleLike/selectArticleLike', { params: params })

// 查询留言
const SELECTLEAVEWORD = (params) =>
  axios.get('leaveWord/selectLeaveWord', { params: params })
// 删除留言
const DELETELEAVEWORD = (params) =>
  axios.post('leaveWord/deleteLeaveWord', params)

// 新增工具分类
const INSERTTOOLSCATEGORY = (params) =>
  axios.post('toolsCategory/insertToolsCategory', params)
// 查询工具分类
const SELECTTOOLSCATEGORY = (params) =>
  axios.get('toolsCategory/selectToolsCategory', { params: params })
// 修改工具分类
const EDITTOOLSCATEGORY = (params) =>
  axios.post('toolsCategory/updateToolsCategory', params)
// 删除工具分类
const DELETETOOLSCATEGORY = (params) =>
  axios.post('toolsCategory/deleteToolsCategory', params)
// 查询所有工具分类
const SELECTALLTOOLSCATEGORY = () =>
  axios.get('toolsCategory/selectAllToolsCategory')

// 新增工具
const INSERTTOOLS = (params) => axios.post('tools/insertTools', params)
// 查询工具
const SELECTTOOLS = (params) =>
  axios.get('tools/selectTools', { params: params })
// 编辑工具
const UODATETOOLS = (params) => axios.post('tools/updateTools', params)
// 删除工具
const DELETETOOLS = (params) => axios.post('tools/deleteTools', params)

// 查询时光机
const SELECTTIMEMACHINE = (params) =>
  axios.get('timeMachine/selectTimeMachine', { params: params })
// 新增时光机
const INSERTTIMEMACHINE = (params) =>
  axios.post('timeMachine/insertTimeMachine', params)
// 更新时光机
const UPDATETIMEMACHINE = (params) =>
  axios.post('timeMachine/updateTimeMachine', params)
// 删除时光机
const DELETETIMEMACHINE = (params) =>
  axios.post('timeMachine/deleteTimeMachine', params)
// 更新时光机展示状态
const UPDATETIMEMACHINEISISSUE = (params) =>
  axios.post('timeMachine/updatetimeMachineIsIsissue', params)
export {
  LOGIN,
  REGIST,
  TEST,
  LOGOUT,
  INSERTCATEGORY,
  DELETECATEGORY,
  UPDATECATEGORY,
  SELECTCATEGORY,
  SELECTALLCATEGORY,
  INSERTTAG,
  DELETETAG,
  UPDATETAG,
  SELECTTAG,
  SELECTALLTAG,
  INSERTARTICLE,
  DELETEARTICLE,
  UPDATEARTICLE,
  SELECTARTICLE,
  SELECTARTICLEBYID,
  UPDATEISISSUE,
  UPDATEISTOP,
  UPLOADPHOTO,
  SELECTARTICLECOMMENT,
  DELETEARTICLECOMMENT,
  SELECTARTICLELIKE,
  SELECTLEAVEWORD,
  DELETELEAVEWORD,
  INSERTTOOLSCATEGORY,
  SELECTTOOLSCATEGORY,
  DELETETOOLSCATEGORY,
  EDITTOOLSCATEGORY,
  SELECTALLTOOLSCATEGORY,
  INSERTTOOLS,
  SELECTTOOLS,
  UODATETOOLS,
  DELETETOOLS,
  SELECTTIMEMACHINE,
  INSERTTIMEMACHINE,
  UPDATETIMEMACHINE,
  DELETETIMEMACHINE,
  UPDATETIMEMACHINEISISSUE
}
